import { EXPIRY_CART, GET_ASSIGNED_AND_DISPATCHED_ORDERS, GET_MONEY_CART, RETURN_CART, ACCEPT_SUMMARY, LOGIN_ROUTE, CLEAR_RETURN_CART, GET_SUMMARIES_AND_DELIVERY_BOYS, ORDER_UPLOAD, CLEAR_EXPIRY_CART, CLEAR_MONEY_CART, ASSIGN_SUMMARY_TO_DELIVERY_BOYS, DISPATCH_SUMMARY, GET_DISPATCHED_SUMMARIES, ORDER_COMPLETE_DE, CLEAR_SUMMARY, DOWNLOAD_REPORT, DELETE_SUMMARY, UPDATE_ORDER, COMBINATION_PAYMENT, UPDATE_UTR_NO, RESET_PAYMENT_DETAILS, RESET_PAYMENT_REQUEST, RESPOND_TO_PAMENT_REQUEST, GET_CREDIT_BILLS, GET_NON_ISSUED_CREDIT_BILLS, ISSUE_CREDIT_BILLS, GET_ASSIGNED_CREDIT_BILLS, ACCEPT_CREDIT_ORDERS, CREDIT_COMBINATION_PAYMENT, ORDER_COMPLETE_SALESMAN, GET_ISSUED_BILLS_SUPERVISOR, GET_CREDIT_MONEY_CART, GET_CREDIT_RETURN_CART, CLEAR_CREDIT_MONEY_CART, UPDATE_SALESMAN_UTR_NUMBER, UPDATE_CREDIT_ORDERS, DOWNLOAD_CREDIT_REPORT_SUPERVISOR, DOWNLOAD_CREDIT_REPORT_SALESMAN, RESET_SALESMAN_PAYMENT_DETAILS, REVERT_COMPLETED_ORDERS, BILL_EDIT_RETURN_CART, CLEAR_BILL_EDIT_RETURN_CART } from '../enums/routes';
import { QueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';

export const queryClient = new QueryClient();
export const loginUser = async (authData) => {
    const response = await fetch(LOGIN_ROUTE, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(authData),
    });

    if (response.status === 401 || response.status === 404) {
        const resData = await response.json();
        throw new Error(resData.message || 'Authentication failed');
    }

    if (!response.ok) {
        throw new Error('Could not authenticate user');
    }

    const resData = await response.json();
    return resData;
};

export const fetchShopData = async (token) => {
    const response = await fetch(GET_ASSIGNED_AND_DISPATCHED_ORDERS, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const result = await response.json();
    if (!response.ok) {
        throw new Error(result.message || 'Failed to fetch data');
    }
    if(response.status === 201){
        return 'No Data Found!'
        //throw new Error('No Summaries were assigned')
    }
    return result.data;
};

export const handleAccept = async ({ uuid,brandId, token,summaryName }) => {
    const apiPayload = {
        isAcceptedByDeliveryBoy: true,
        uuid: uuid,
        brandId,
        summaryName
    };

    const response = await fetch(ACCEPT_SUMMARY, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(apiPayload),
    });

    const result = await response.json();

    if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
    }

    return result.message || 'Summary Accepted Successfully';
};

export const fetchMoneyCartData = async (token,deliveryBoyId,summaryName,brandId) => {

      const response = await fetch(`${GET_MONEY_CART}${summaryName}&deliveryBoyId=${deliveryBoyId}&brandId=${brandId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    const result = await response.json();
  
    if (!response.ok) {
        throw new Error(result.message || 'Failed to fetch data');
    }
    return result.data;
  };

  export const fetchCartData = async ({cartMode, deliveryBoyId, token, summaryName ,brandId}) => {
    let apiEndPoints = RETURN_CART;
    if (cartMode === 'expiry') {
        apiEndPoints = EXPIRY_CART;
    }
    if(cartMode === 'billEditReturn'){
      apiEndPoints = BILL_EDIT_RETURN_CART
    }

    const response = await fetch(`${apiEndPoints}${summaryName}&deliveryBoyId=${deliveryBoyId}&brandId=${brandId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    });

    const result = await response.json();
    if (!response.ok) {
        throw new Error(result.message || 'Error fetching cart data');
    }
    return result.data;
};

export const clearReturnCart = async ({ deliveryBoyId, token,summaryName,brandId }) => {
    const response = await fetch(CLEAR_RETURN_CART, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ deliveryBoyId,summaryName,brandId }),
    });

    const result = await response.json();

    if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
    }

    return result.message || 'Return Cart Cleared Successfully';
  };

export const clearExpiryCart = async ({ deliveryBoyId, token,summaryName,brandId }) => {
    const response = await fetch(CLEAR_EXPIRY_CART,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ deliveryBoyId ,summaryName,brandId}),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const message = errorData.message;
        throw new Error(message||'Failed to clear expiry cart');
    }

    return await response.json();
};

export const clearBillEditReturnCart = async ({ deliveryBoyId, token,summaryName,brandId }) => {
  const response = await fetch(CLEAR_BILL_EDIT_RETURN_CART,{
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ deliveryBoyId ,summaryName,brandId}),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const message = errorData.message;
      throw new Error(message||'Failed to clear return cart');
  }

  return await response.json();
};

export const clearMoneyCart = async ({ token, deliveryBoyId, mode ,summaryName,brandId}) => {
    const body = { deliveryBoyId ,summaryName, brandId};
  
    if (mode === "Credit") {
      body.clearCredit = true;
    } else if (mode === "Cheque") {
      body.clearCheque = true;
    } else if (mode === "Online") {
      body.clearOnline = true;
    }
  
    const response = await fetch(CLEAR_MONEY_CART, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const result = response.json();
  
    if (!response.ok) {
      throw new Error(result.message || "Network response was not ok");
    }
  
    return result;
  };

  export const allocateSummary = async ({ summaryUuid, deliveryBoyId,brandId,summaryName,token }) => {
    const response = await fetch(`${ASSIGN_SUMMARY_TO_DELIVERY_BOYS}${summaryUuid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ deliveryBoyId,brandId,summaryName }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to allocate summary');
    }
  
    return response.json();
  };

  export const dispatchSummary = async ({ summaryId,brandId,summaryName, token }) => {
    const response = await fetch(`${DISPATCH_SUMMARY}?uuid=${summaryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({brandId,summaryName}),
    });
  
    if (!response.ok) {
      throw new Error('Failed to dispatch summary');
    }
  
    return response.json();
  };

  export const fetchSummariesAndDeliveryBoys = async (token, brandId) => {
    const response = await fetch(`${GET_SUMMARIES_AND_DELIVERY_BOYS}${brandId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    
    if (!response.ok) {
        throw new Error('Failed to fetch summaries and delivery boys');
    }

    const data = await response.json();

    if (response.status === 201) {
        return { isSummaryEmpty: true };
    } else if (data.success) {
        return { isSummaryEmpty: false, summaries: data.data.summaries, deliveryBoys: data.data.deliveryBoys,downloadableSummaries:data.data.summaryNames };
    }

    throw new Error(data.message);
};

export const fetchDispatchedSummaries = async ({ brandId, uuid, deliveryBoyId, summaryName, token }) => {
  let apiUrl = `${GET_DISPATCHED_SUMMARIES}`;
  let queryParams = {
      brandId: brandId,
      uuid: uuid,
      deliveryBoyId: deliveryBoyId,
      summaryName:summaryName
  };

  // Add query parameters to the URL
  const queryString = new URLSearchParams(queryParams).toString();
  const urlWithQuery = apiUrl + (queryString ? `?${queryString}` : '');

  const response = await fetch(urlWithQuery, {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  });

  const result = await response.json();

  if (!response.ok || !result.success) {
      throw new Error(result.message || 'Error fetching data');
  }

  return result.data;
};

export const completeOrders = async ({ summaryName,brandId, token }) => {
  const response = await fetch(ORDER_COMPLETE_DE, {
      method: 'PUT',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ summaryName,brandId }),
  });

  if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Something went wrong');
  }

  return response.json();
};

export const clearSummary = async ({ deliveryBoyId, summaryName,brandId, token, }) => {
  const response = await fetch(`${CLEAR_SUMMARY}${deliveryBoyId}&summaryName=${summaryName}&brandId=${brandId}`, {
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  });

  if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to clear summary');
  }

  return response.json();
};

export const downloadReport = async ({ brandId, summaryName, deliveryBoyId, token }) => {
  const url = `${DOWNLOAD_REPORT}${brandId}&summaryName=${summaryName}&deliveryBoyId=${deliveryBoyId}`;
  
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.message || 'Failed to download the report');
  }

  return await response.blob(); // Return the file as a blob for download
};

export const clearCashCart = async ({ deliveryBoyId, token,summaryName,brandId }) => {
  const response = await fetch(CLEAR_MONEY_CART, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          summaryName,
          deliveryBoyId,
          brandId,
          clearCash: true,
      }),
  });

  if (!response.ok) {
      throw new Error(response.message || 'Failed to clear money cart');
  }

  return await response.json();
};

export const deleteSummary = async ({summaryName, brandId, token}) => {
  const response = await fetch(`${DELETE_SUMMARY}${summaryName}&brandId=${brandId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete summary');
  }

  return await response.json();
};

export const updateUtrNumber = async ({ brandId, brandOrderId, summaryName,paymentMethod, utrNumber, token }) => {
  const response = await fetch(UPDATE_UTR_NO, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
          brandId,
          brandOrderId,
          summaryName,
          paymentMethod,
          utrNumber
      }),
  });

  if (!response.ok) {
    const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update UTR');
  }

  return response.json();
};

export const updatePaymentDetails = async (formData, token, role) => {
  const apiRoute = role === 'deliveryBoy' ? COMBINATION_PAYMENT : CREDIT_COMBINATION_PAYMENT;
  const response = await fetch(apiRoute, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to update payment details.");
  }

  return await response.json();
};

export const resetPaymentDetails = async ({token, brandId, brandOrderId, summaryName }) => {
  const response = await fetch(RESET_PAYMENT_DETAILS, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          brandId,
          brandOrderId,
          summaryName,
      }),
  });

  if (!response.ok) {
      throw new Error(response.message || 'Failed to reset Payment Details');
  }

  return await response.json();
};

export const resetPaymentRequest = async ({token, brandId, brandOrderId, summaryName }) => {
  const response = await fetch(RESET_PAYMENT_REQUEST, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          brandId,
          brandOrderId,
          summaryName,
      }),
  });

  if (!response.ok) {
      throw new Error(response.message || 'Failed to send reset Payment Request!');
  }

  return await response.json();
};

export const respondResetPaymentRequest = async ({token, brandId, brandOrderId, summaryName,action }) => {
  const response = await fetch(RESPOND_TO_PAMENT_REQUEST, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          brandId,
          brandOrderId,
          summaryName,
          action,
      }),
  });

  if (!response.ok) {
      throw new Error(response.message || 'Failed to respond to reset Payment Request!');
  }

  return await response.json();
};

export const fetchCreditOrders = async (token, brandId) => {
  const response = await fetch(`${GET_CREDIT_BILLS}${brandId}`, {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message || 'Failed to fetch credit data.');
  }

  if(response.status === 201){
     return 'No Credit Orders Found!';
  }

return result.data;
};

export const fetchNonIssuedOrders = async ({ token, brandId }) => {
  const response = await fetch(`${GET_NON_ISSUED_CREDIT_BILLS}${brandId}`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  });
  const result = await response.json();
  
  if (!response.ok) {
      throw new Error(result.message || 'Error fetching non-issued orders');
  }
  
  return result.data;
};

export const assignOrdersToSalesman = async ({ token, uniqueOrderIds, brandId, salesmanId }) => {
  const response = await fetch(ISSUE_CREDIT_BILLS, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
          uniqueOrderIds,
          brandId,
          salesmanId
      })
  });

  if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Error assigning orders');
  }
  return response.json();
};

export const fetchAssignedCreditBills = async (token) => {
  const response = await fetch(GET_ASSIGNED_CREDIT_BILLS, {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  });
  const result = await response.json();
  if (!response.ok) {
      throw new Error(result.message || 'Failed to fetch data');
  }
  if(response.status === 201){
      return 'No Data Found!'
      //throw new Error('No Summaries were assigned')
  }
  return result.data;
};

export const acceptOrdersBySalesman = async ({ token, uniqueOrderIds }) => {
  const response = await fetch(ACCEPT_CREDIT_ORDERS, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
          uniqueOrderIds,
          isAcceptedBySalesman:true,
      })
  });

  if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Error assigning orders');
  }
  return response.json();
};

export const completeCreditOrders = async ({ uniqueOrderIds,token,action }) => {
  const response = await fetch(ORDER_COMPLETE_SALESMAN, {
      method: 'PUT',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uniqueOrderIds,action }),
  });

  if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Something went wrong');
  }

  return response.json();
};

export const fetchIssuedBillsSupervisor = async ({token, brandId,salesmanId}) => {
  const response = await fetch(`${GET_ISSUED_BILLS_SUPERVISOR}${brandId}&salesmanId=${salesmanId}`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`,
      },
  });
  
  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.message || 'Failed to fetch issued bills');
}

  const data = await response.json();

  if(response.status === 201){
      return 'No Data Found!'
      //throw new Error('No Summaries were assigned')
  }
  return data.data;
};

export const fetchCreditMoneyCartData = async (token,brandId,salesmanId) => {

  const response = await fetch(`${GET_CREDIT_MONEY_CART}${brandId}&salesmanId=${salesmanId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
const result = await response.json();

if (!response.ok) {
    throw new Error(result.message || 'Failed to fetch data');
}
return result.data;
};

export const clearCreditCashCart = async (salesmanId, brandId, token) => {
  const response = await fetch(CLEAR_CREDIT_MONEY_CART, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          salesmanId,
          brandId,
          clearCash: true,
      }),
  });
  const result = await response.json();

  if (!response.ok) {
      throw new Error(result.message || 'Failed to clear money cart');
  }

  return await result;
};

export const clearCreditMoneyCart = async (salesmanId, mode ,brandId,token) => {
  const body = { salesmanId , brandId};

  if (mode === "Credit") {
    body.clearCredit = true;
  } else if (mode === "Cheque") {
    body.clearCheque = true;
  } else if (mode === "Online") {
    body.clearOnline = true;
  }

  const response = await fetch(CLEAR_CREDIT_MONEY_CART, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const result = await response.json();

  if (!response.ok) {
    throw new Error(result.error || "Network response was not ok");
  }

  return result;
};

export const updateCreditBillsUtrNumber = async ({ brandId, brandOrderId, summaryName,paymentMethod, utrNumber, token }) => {
  const response = await fetch(UPDATE_SALESMAN_UTR_NUMBER, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
          brandId,
          brandOrderId,
          summaryName,
          paymentMethod,
          utrNumber
      }),
  });

  if (!response.ok) {
    const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update UTR');
  }

  return response.json();
};

export const clearCreditBills = async ({ brandId, uniqueOrderIds, token }) => {
  const response = await fetch(UPDATE_CREDIT_ORDERS, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
          brandId,
          uniqueOrderIds,
      }),
  });

  if (!response.ok) {
    const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to clear credit bills.');
  }

  return response.json();
};

export const downloadCreditReportSupervisor = async ({ brandId,salesmanId, token }) => {
  const url = `${DOWNLOAD_CREDIT_REPORT_SUPERVISOR}${brandId}&salesmanId=${salesmanId}`;
  
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.message || 'Failed to download the report');
  }

  return await response.blob(); // Return the file as a blob for download
};

export const downloadCreditReportSalesman = async ({ brandId,supervisorId, token }) => {
  const url = `${DOWNLOAD_CREDIT_REPORT_SALESMAN}${brandId}&supervisorId=${supervisorId}`;
  
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.message || 'Failed to download the report');
  }

  return await response.blob(); // Return the file as a blob for download
};

export const resetSalesmanPaymentDetails = async ({token, brandId, brandOrderId}) => {
  const response = await fetch(RESET_SALESMAN_PAYMENT_DETAILS, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          brandId,
          brandOrderId,
      }),
  });

  if (!response.ok) {
      throw new Error(response.message || 'Failed to reset Payment Details');
  }

  return await response.json();
};

export const revertCompletedOrders = async ({ deliveryBoyId,summaryName,brandId,token }) => {
  const response = await fetch(REVERT_COMPLETED_ORDERS, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
          deliveryBoyId,
          summaryName,
          brandId
      }),
  });

  if (!response.ok) {
    const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to revert completed orders.');
  }

  return response.json();
};

// export const useGetSummariesAndDeliveryBoys = (brandId, token) => {
//   return useQuery({
//     queryKey: ['summariesAndDeliveryBoys', brandId],
//     queryFn: async () => {
//       const response = await fetch(`${GET_SUMMARIES_AND_DELIVERY_BOYS}${brandId}`, {
//         method: 'GET',
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         },
//       });
//       const data = await response.json();
//       if (response.ok) {
//         return data;
//       }
//       throw new Error(data.message || 'Error fetching summaries and delivery boys');
//     },
//     enabled: !!brandId, // Only run if brandId is provided
//   });
// };

// export const useUploadFile = (token) => {
//   return useMutation({
//     mutationFn: async ({ file, brandId }) => {
//       const formData = new FormData();
//       formData.append('file', file);
//       formData.append('brandId', brandId);

//       const response = await fetch(ORDER_UPLOAD, {
//         method: 'POST',
//         body: formData,
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         },
//       });

//       if (!response.ok) {
//         throw new Error(response.status === 400 ? 'This File has already been uploaded' : 'Failed to upload file. Please try again.');
//       }

//       return response.json();
//     },
//   });
// };


//   export const fetchSummariesAndDeliveryBoys = async (brandId, token) => {
//     const response = await fetch(`${GET_SUMMARIES_AND_DELIVERY_BOYS}${brandId}`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//       },
//     });
//     if (!response.ok) {
//       throw new Error('Failed to fetch summaries and delivery boys');
//     }
//     return response.json();
//   };

//   export const uploadFile = async (file, brandId, token) => {
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('brandId', brandId);
  
//     const response = await fetch(ORDER_UPLOAD, {
//       method: 'POST',
//       body: formData,
//       headers: {
//         'Authorization': `Bearer ${token}`,
//       },
//     });
  
//     if (!response.ok) {
//       if (response.status === 400) {
//         throw new Error('This File has already been uploaded');
//       }
//       throw new Error('Failed to upload file');
//     }
  
//     return response.json();
//   };