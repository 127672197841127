import { configureStore } from '@reduxjs/toolkit';
import supervisorReducer from './supervisor-slice.js';
import deliveryBoyReducer from './deliveryExecutive-slice.js';
import salesmanReducer from './salesman-slice.js';
import roleReducer from './role-slice.js';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedSupervisorReducer = persistReducer(persistConfig, supervisorReducer);
// const persistedDeliveryBoyReducer =persistReducer(persistConfig,deliveryBoyReducer);
// const persistedRoleReducer = persistReducer(persistConfig,roleReducer);
// const persistedSalesmanReducer = persistReducer(persistConfig,salesmanReducer);

// // Define separate persist configs for each slice
const supervisorPersistConfig = { key: 'supervisor', storage };
const deliveryBoyPersistConfig = { key: 'deliveryBoy', storage };
const rolePersistConfig = { key: 'role', storage };
const salesmanPersistConfig = { key: 'salesman', storage };

// // Wrap each reducer with its own persist config
const persistedSupervisorReducer = persistReducer(supervisorPersistConfig, supervisorReducer);
const persistedDeliveryBoyReducer = persistReducer(deliveryBoyPersistConfig, deliveryBoyReducer);
const persistedRoleReducer = persistReducer(rolePersistConfig, roleReducer);
const persistedSalesmanReducer = persistReducer(salesmanPersistConfig, salesmanReducer);

const store = configureStore({
  reducer: {
    supervisor: persistedSupervisorReducer, // Use the persisted reducer here
    deliveryBoy: persistedDeliveryBoyReducer,
    role:persistedRoleReducer,
    salesman:persistedSalesmanReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };