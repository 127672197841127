import { Outlet } from "react-router-dom"
import Header from "../../Components/Header/Header"

function RootLayoutSalesman() {
    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}
export default RootLayoutSalesman;