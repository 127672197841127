import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSupervisorData } from '../../store/supervisor-slice';
import classes from './Header.module.css';
import { clearDeliveryBoyData } from '../../store/deliveryExecutive-slice';
import { clearRole } from '../../store/role-slice';
import { clearSalesmanData } from '../../store/salesman-slice';
import { persistor } from '../../store/store';

function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.supervisor.token);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Clear the token from local storage
        localStorage.removeItem('role');
        dispatch(clearSupervisorData()); // Clear the Redux state
        dispatch(clearDeliveryBoyData());
        dispatch(clearRole());
        dispatch(clearSalesmanData());
        // Purge persisted state
        persistor.purge();
        navigate('/login'); // Redirect to the login page
    };

    // If token is null after logout, redirect to login page
    // useEffect(() => {
    //     if (!token) {
    //         navigate('/login');
    //     }
    // }, [token, navigate]);

    // Do not show the logout button on the login page
    const isLoginPage = location.pathname === '/login';

    return (
        <div className={classes.header}>
            <div className={classes.companyinfo}>
                <h1 className={classes.companyname}>Urban Traders</h1>
                <p className={classes.companylocation}>Hyderabad</p>
            </div>
            {!isLoginPage && (
                <button className={classes.logout} onClick={handleLogout}>
                    Logout
                </button>
            )}
        </div>
    );
}

export default Header;
