import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  salesmanDetails: { id: '', userName: '' },
  token: null,
  brandId:'',
};

const salesmanSlice = createSlice({
  name: 'salesman',
  initialState,
  reducers: {
    setSalesmanData: (state, action) => {
      state.salesmanDetails = action.payload.salesmanDetails;
      state.token = action.payload.token;
    },
    setSalesmanBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    clearSalesmanData: (state) => {
      state.salesmanDetails = null;
      state.token = null;
    },
  },
});

export const { setSalesmanData,clearSalesmanData,setSalesmanBrandId } = salesmanSlice.actions;

export default salesmanSlice.reducer;

