import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import "./App.css";
import { checkAuthLoader, checkAuthLoaderCommon, checkAuthLoaderDelivery, checkAuthLoaderDeliveryAndSalesman, checkAuthLoaderSalesman, checkAuthLoaderSupervisor } from "./utils/authCustom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./http/https";
import GlobalLoader from "./Assets/Loader/GlobalLoader";
import RootLayoutSalesman from "./Pages/Salesman/RootLayoutSalesman";

// Lazy loading components
const DeliveryBoyDashboard = lazy(() => import("./Pages/DeliveryBoy/DeliveryBoyDashboard"));
const MoneyCart = lazy(() => import("./Pages/DeliveryBoy/MoneyCart"));
const ReturnCart = lazy(() => import("./Pages/DeliveryBoy/ReturnCart"));
const ExpiryCart = lazy(() => import("./Pages/DeliveryBoy/ExpiryCart"));
const BillCombination = lazy(() => import("./Pages/BillCombination/BillCombination"));
const SuperviorDashboard = lazy(() => import("./Pages/Supervisor/SuperisorDashboard"));
const IssueBills = lazy(() => import("./Pages/Supervisor/Credit/IssueBills"));
const ClearBills = lazy(() => import("./Pages/Supervisor/Credit/BillClearence"));
const SalesmanDashboard = lazy(() => import("./Pages/Salesman/SalesmanDashboard"));
const RootLayoutDelivery = lazy(() => import("./Pages/DeliveryBoy/RootLayoutDelivery"));
const RootLayoutSupervisor = lazy(() => import("./Pages/Supervisor/RootLayoutSupervisor"));
const LogInPage = lazy(() => import("./Pages/LogIn/LogInPage"));
const AccountantDashboard = lazy(() => import("./Pages/Accountant/AccountantDashboard"));
const RootLayoutAccountant = lazy(()=>import("./Pages/Accountant/RootLayoutAccountant"));
const BillEditReturnCart = lazy(()=>import("./Pages/DeliveryBoy/BillEditReturnCart"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<GlobalLoader />}>
        <RootLayoutDelivery />
      </Suspense>
    ),
    children: [
      {
        path: "login",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <LogInPage />
          </Suspense>
        ),
      },
      {
        index: true,
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <DeliveryBoyDashboard />
          </Suspense>
        ),
        loader: checkAuthLoaderDelivery,
      },
      {
        path: "money",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <MoneyCart />
          </Suspense>
        ),
        loader: checkAuthLoaderCommon,
      },
      {
        path: "return",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <ReturnCart />
          </Suspense>
        ),
        loader: checkAuthLoaderCommon,
      },
      {
        path: "expiry",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <ExpiryCart />
          </Suspense>
        ),
        loader: checkAuthLoaderCommon,
      },
      {
        path: "billEditReturn",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <BillEditReturnCart />
          </Suspense>
        ),
        loader: checkAuthLoaderCommon,
      },
      {
        path: "billcombination",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <BillCombination />
          </Suspense>
        ),
        loader: checkAuthLoaderDeliveryAndSalesman,
      },
    ],
  },
  {
    path: "/supervisordashboard",
    element: (
      <Suspense fallback={<GlobalLoader />}>
        <RootLayoutSupervisor />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <SuperviorDashboard />
          </Suspense>
        ),
        loader: checkAuthLoaderSupervisor,
      },
      {
        path: "/supervisordashboard/issuebills",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <IssueBills />
          </Suspense>
        ),
        loader: checkAuthLoaderSupervisor,
      },
      {
        path: "/supervisordashboard/clearbills",
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <ClearBills />
          </Suspense>
        ),
        loader: checkAuthLoaderSupervisor,
      },
    ],
  },
  {
    path: "/salesmandashboard",
    element: (
      <Suspense fallback={<GlobalLoader />}>
        <RootLayoutSalesman />
      </Suspense>
    ),
    children:[
      {
        index:true,
        element:(
          <Suspense fallback={<GlobalLoader />}>
            <SalesmanDashboard />
          </Suspense>
        ),
        loader: checkAuthLoaderSalesman,
      }
    ]
  },
  {
    path: "/accountantdashboard",
    element: (
      <Suspense fallback={<GlobalLoader />}>
        <RootLayoutAccountant />
      </Suspense>
    ),
    children:[
      {
        index:true,
        element:(
          <Suspense fallback={<GlobalLoader />}>
            <AccountantDashboard />
          </Suspense>
        ),
        loader: checkAuthLoaderSupervisor,
      }
    ]
  },
]);

function App() {
  return (
     <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
