export const LOGIN_ROUTE = 'https://alizretailbackend.in/api/login';
export const ORDER_UPLOAD = 'https://alizretailbackend.in/api/upload/order_upload';
export const ASSIGN_SUMMARY_TO_DELIVERY_BOYS = 'https://alizretailbackend.in/api/summary/assign_summary_to_delivery_boy?uuid=';
export const GET_SUMMARIES_AND_DELIVERY_BOYS = 'https://alizretailbackend.in/api/summary/get_summaries_and_delivery_boys?brandId=';
export const GET_ASSIGNED_AND_DISPATCHED_ORDERS = 'https://alizretailbackend.in/api/delivery_boy/get_assigned_and_disptached_orders';
export const ACCEPT_SUMMARY = 'https://alizretailbackend.in/api/delivery_boy/accept_order_by_delivery_boy';
export const PAYMENTS = 'https://alizretailbackend.in/api/upload/payment';
export const GET_MONEY_CART='https://alizretailbackend.in/api/carts/get_money_cart?summaryName=';
export const CLEAR_MONEY_CART ='https://alizretailbackend.in/api/supervisor/clear_money_cart';
export const DISPATCH_SUMMARY ='https://alizretailbackend.in/api/summary/dispatch_assigned_summary_to_delivery_boy';
export const RETURN_CART = 'https://alizretailbackend.in/api/carts/get_return_summary_cart?summaryName=';
export const EXPIRY_CART = 'https://alizretailbackend.in/api/carts/get_expiry_summary_cart?summaryName=';
export const GET_DISPATCHED_SUMMARIES = 'https://alizretailbackend.in/api/summary/get_dispatched_summaries';
export const CLEAR_RETURN_CART = 'https://alizretailbackend.in/api/supervisor/clear_return_cart';
export const CLEAR_EXPIRY_CART = 'https://alizretailbackend.in/api/supervisor/clear_expiry_cart';
export const ORDER_COMPLETE_DE = 'https://alizretailbackend.in/api/delivery_boy/complete_orders';
export const CLEAR_SUMMARY = 'https://alizretailbackend.in/api/summary/clear_summary?deliveryBoyId=';
export const DOWNLOAD_REPORT = 'https://alizretailbackend.in/api/download/download_summary?brandId=';
export const DELETE_SUMMARY = 'https://alizretailbackend.in/api/summary/delete_summary?summaryName=';
export const UPDATE_ORDER = 'https://alizretailbackend.in/api/supervisor/update_order';
export const COMBINATION_PAYMENT = 'https://alizretailbackend.in/api/upload/combination-payment';
export const UPDATE_UTR_NO='https://alizretailbackend.in/api/supervisor/update_utr_number';
export const RESET_PAYMENT_DETAILS='https://alizretailbackend.in/api/delivery_boy/reset_payment_details';
export const RESET_PAYMENT_REQUEST='https://alizretailbackend.in/api/delivery_boy/reset_payment_details_request';
export const RESPOND_TO_PAMENT_REQUEST='https://alizretailbackend.in/api/supervisor/reset_payment_request';
export const GET_CREDIT_BILLS='https://alizretailbackend.in/api/credit/get_credit_orders?brandId=';
export const GET_NON_ISSUED_CREDIT_BILLS='https://alizretailbackend.in/api/credit/get_non_issue_orders?brandId=';
export const ISSUE_CREDIT_BILLS='https://alizretailbackend.in/api/credit/assign_orders_to_salesman';
export const GET_ASSIGNED_CREDIT_BILLS='https://alizretailbackend.in/api/salesman/get_assigned_credit_orders';
export const ACCEPT_CREDIT_ORDERS ='https://alizretailbackend.in/api/salesman/accept_and_deny_orders';
export const CREDIT_PAYMENT= 'https://alizretailbackend.in/api/upload/credit_payment';
export const CREDIT_COMBINATION_PAYMENT= 'https://alizretailbackend.in/api/upload/credit_combination_payment';
export const ORDER_COMPLETE_SALESMAN='https://alizretailbackend.in/api/salesman/complete_orders_by_salesman';
export const GET_ISSUED_BILLS_SUPERVISOR='https://alizretailbackend.in/api/credit/get_issued_orders_of_salesman?brandId=';
export const GET_CREDIT_MONEY_CART='https://alizretailbackend.in/api/carts/get_salesman_money_cart?brandId=';
export const CLEAR_CREDIT_MONEY_CART='https://alizretailbackend.in/api/supervisor/clear_salesman_money_cart';
export const UPDATE_SALESMAN_UTR_NUMBER='https://alizretailbackend.in/api/supervisor/update_salesman_utr_number';
export const UPDATE_CREDIT_ORDERS='https://alizretailbackend.in/api/credit/update_credit_orders';
export const DOWNLOAD_CREDIT_REPORT_SALESMAN ='https://alizretailbackend.in/api/download/credit_orders_report?brandId=';
export const DOWNLOAD_CREDIT_REPORT_SUPERVISOR='https://alizretailbackend.in/api/download/credit_orders_report_of_salesman?brandId=';
export const RESET_SALESMAN_PAYMENT_DETAILS='https://alizretailbackend.in/api/salesman/reset_payment_details_for_salesman';
export const REVERT_COMPLETED_ORDERS='https://alizretailbackend.in/api/summary/revert_completed_orders_flags';
export const REVERT_COMPLETED_ORDERS_SALESMAN='https://alizretailbackend.in/api/credit/revert_completed_orders_flags_of_salesman';
export const BILL_EDIT_RETURN_CART='https://alizretailbackend.in/api/carts/get_returned_amount_cart?summaryName=';
export const CLEAR_BILL_EDIT_RETURN_CART='https://alizretailbackend.in/api/supervisor/clear_returned_amount_cart';

//local routes

// export const LOGIN_ROUTE = 'http://localhost:5000/api/login';
// export const ORDER_UPLOAD = 'http://localhost:5000/api/upload/order_upload';
// export const ASSIGN_SUMMARY_TO_DELIVERY_BOYS = 'http://localhost:5000/api/summary/assign_summary_to_delivery_boy?uuid=';
// export const GET_SUMMARIES_AND_DELIVERY_BOYS = 'http://localhost:5000/api/summary/get_summaries_and_delivery_boys?brandId=';
// export const GET_ASSIGNED_AND_DISPATCHED_ORDERS = 'http://localhost:5000/api/delivery_boy/get_assigned_and_disptached_orders';
// export const ACCEPT_SUMMARY = 'http://localhost:5000/api/delivery_boy/accept_order_by_delivery_boy';
// export const PAYMENTS = 'http://localhost:5000/api/upload/payment';
// export const GET_MONEY_CART = 'http://localhost:5000/api/carts/get_money_cart?summaryName=';
// export const CLEAR_MONEY_CART = 'http://localhost:5000/api/supervisor/clear_money_cart';
// export const DISPATCH_SUMMARY = 'http://localhost:5000/api/summary/dispatch_assigned_summary_to_delivery_boy';
// export const RETURN_CART = 'http://localhost:5000/api/carts/get_return_summary_cart?summaryName=';
// export const EXPIRY_CART = 'http://localhost:5000/api/carts/get_expiry_summary_cart?summaryName=';
// export const GET_DISPATCHED_SUMMARIES = 'http://localhost:5000/api/summary/get_dispatched_summaries';
// export const CLEAR_RETURN_CART = 'http://localhost:5000/api/supervisor/clear_return_cart';
// export const CLEAR_EXPIRY_CART = 'http://localhost:5000/api/supervisor/clear_expiry_cart';
// export const ORDER_COMPLETE_DE = 'http://localhost:5000/api/delivery_boy/complete_orders';
// export const CLEAR_SUMMARY = 'http://localhost:5000/api/summary/clear_summary?deliveryBoyId=';
// export const DOWNLOAD_REPORT = 'http://localhost:5000/api/download/download_summary?brandId=';
// export const DELETE_SUMMARY = 'http://localhost:5000/api/summary/delete_summary?summaryName=';
// export const UPDATE_ORDER = 'http://localhost:5000/api/supervisor/update_order';
// export const COMBINATION_PAYMENT = 'http://localhost:5000/api/upload/combination-payment';
// export const UPDATE_UTR_NO='http://localhost:5000/api/supervisor/update_utr_number';
// export const RESET_PAYMENT_DETAILS='http://localhost:5000/api/delivery_boy/reset_payment_details';
// export const RESET_PAYMENT_REQUEST='http://localhost:5000/api/delivery_boy/reset_payment_details_request';
// export const RESPOND_TO_PAMENT_REQUEST='http://localhost:5000/api/supervisor/reset_payment_request';
// export const GET_CREDIT_BILLS='http://localhost:5000/api/credit/get_credit_orders?brandId=';
// export const GET_NON_ISSUED_CREDIT_BILLS='http://localhost:5000/api/credit/get_non_issue_orders?brandId=';
// export const ISSUE_CREDIT_BILLS='http://localhost:5000/api/credit/assign_orders_to_salesman';
// export const GET_ASSIGNED_CREDIT_BILLS='http://localhost:5000/api/salesman/get_assigned_credit_orders';
// export const ACCEPT_CREDIT_ORDERS ='http://localhost:5000/api/salesman/accept_and_deny_orders';
// export const CREDIT_PAYMENT= 'http://localhost:5000/api/upload/credit_payment';
// export const CREDIT_COMBINATION_PAYMENT= 'http://localhost:5000/api/upload/credit_combination_payment';
// export const ORDER_COMPLETE_SALESMAN='http://localhost:5000/api/salesman/complete_orders_by_salesman';
// export const GET_ISSUED_BILLS_SUPERVISOR='http://localhost:5000/api/credit/get_issued_orders_of_salesman?brandId=';
// export const GET_CREDIT_MONEY_CART='http://localhost:5000/api/carts/get_salesman_money_cart?brandId=';
// export const CLEAR_CREDIT_MONEY_CART='http://localhost:5000/api/supervisor/clear_salesman_money_cart';
// export const UPDATE_SALESMAN_UTR_NUMBER='http://localhost:5000/api/supervisor/update_salesman_utr_number';
// export const UPDATE_CREDIT_ORDERS='http://localhost:5000/api/credit/update_credit_orders';
// export const DOWNLOAD_CREDIT_REPORT_SALESMAN ='http://localhost:5000/api/download/credit_orders_report?brandId=';
// export const DOWNLOAD_CREDIT_REPORT_SUPERVISOR='http://localhost:5000/api/download/credit_orders_report_of_salesman?brandId=';
// export const RESET_SALESMAN_PAYMENT_DETAILS='http://localhost:5000/api/salesman/reset_payment_details_for_salesman';
// export const REVERT_COMPLETED_ORDERS='http://localhost:5000/api/summary/revert_completed_orders_flags';
// export const REVERT_COMPLETED_ORDERS_SALESMAN='http://localhost:5000/api/credit/revert_completed_orders_flags_of_salesman';
// export const BILL_EDIT_RETURN_CART='http://localhost:5000/api/carts/get_returned_amount_cart?summaryName=';
// export const CLEAR_BILL_EDIT_RETURN_CART='http://localhost:5000/api/supervisor/clear_returned_amount_cart';
